<template>
	<div class="return">
		
	</div>
</template>

<script>
export default {
	data () {
		return {
			
		}
	},

	mounted(){
		this.onhuitiao()
	},

	methods: {
		onhuitiao(){
			var prame = {
				auth_code:this.$route.query.auth_code,
			}
			this.$service.post(this.$api.postqcauth,prame, (res)=> {
				if(res.code == '200'){
					this.$message({message: '授权成功',type: 'success'});
					setTimeout(function (){
						window.location.href = '/dataShow'
					}, 2000);
				}else{
					this.$message({message: res.msg,type: 'warning'});
					setTimeout(function (){
						window.location.href = '/dataShow'
					}, 3000);
				}
			})
			
		}
	}
}
</script>

<style lang="scss" >
.return{
	position: absolute;
	
}
</style>
